import type { IconProps } from '../types/icons';

export default function ArrowLeftIcon({
  className = '',
  width = 20,
  height = 20,
  fill = 'none',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <g id="arrow-left" transform={`scale(${width / 20},${height / 20}`}>
        <path
          id="Vector"
          d="M16.6666 9.16666V10.8333H6.66655L11.2499 15.4167L10.0666 16.6L3.46655 9.99999L10.0666 3.39999L11.2499 4.58333L6.66655 9.16666H16.6666Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
