import compact from 'lodash/compact';

export default function getFullName(user: {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  maidenName?: string;
}) {
  const { firstName, middleName, lastName, maidenName } = user;

  const fields = [
    firstName,
    maidenName !== lastName && maidenName,
    middleName,
    lastName,
  ];

  return compact(fields).join(' ');
}
