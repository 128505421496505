import Select from 'react-select';
import type { ActionMeta, StylesConfig } from 'react-select';

type TailwindSelectProps = {
  placeholder?: string;
  label?: string;
  value?: string;
  // eslint-disable-next-line
  onChange?: any;
  inputClassName?: string;
  selectedClassName?: string;
  className?: string;
  options?: (string | undefined)[];
  disabled?: boolean;
  dropdownButoonClassNames?: string;
};

export default function TailwindSelect({
  placeholder,
  label,
  value,
  onChange,
  selectedClassName,
  className,
  options,
  disabled = false,
  dropdownButoonClassNames,
}: TailwindSelectProps) {
  const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    onChange && onChange((newValue as { label: string; value: string })?.value);
  };

  const colourStyles: StylesConfig = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      fontSize: '14px',
      marginTop: '4px',
      backgroundColor: isDisabled ? '#F3F4F6' : 'white',
      height: '48px',
      borderRadius: '6px',
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid rgb(209 213 219)',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid rgb(209 213 219)',
      },
      ':focus': {
        ...styles[':focus'],
        outline: 'none', // Remove outline on focus
        boxShadow: 'inset 0 0 0 calc(1px + 0px)  rgb(156 163 175);', // Remove box-shadow on focus
        borderColor: `rgb(156 163 175)`, // Border color when focused
      },
      ':focus-within': {
        ...styles[':focus-within'],
        outline: 'none', // Remove outline on focus within
        boxShadow: 'inset 0 0 0 calc(1px + 0px)  rgb(156 163 175);', // Remove box-shadow on focus within
        borderColor: `rgb(156 163 175)`, // Border color when focused
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,

      fontSize: '14px',
      boxShadow: 'none',
      backgroundColor: isDisabled
        ? '#F3F4F6'
        : isSelected
        ? '#F3F4F6'
        : isFocused
        ? '#F3F4F6'
        : undefined,
      color: 'black',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#F3F4F6',
      },
    }),
    input: (styles) => ({
      ...styles,
      outline: 'none',
      boxShadow: '0',
      borderColor: 'rgb(156 163 175)',
      border: 'none',
      ':focus': {
        outline: 'none',
        boxShadow: 'none !important',
        borderColor: `rgb(156 163 175)`,
      },
      ':focus-visible': {
        outline: 'none',
        boxShadow: 'none',
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      boxShadow: 'none',
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      boxShadow: 'none',
    }),
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <span
        className={`text-xs md:text-sm flex pl-[5px] leading-[17px] tracking-[0.01em] font-[500] text-[#00000080] transition ${className}`}
      >
        {label}
      </span>
      <Select
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={false}
        isClearable={true}
        value={value ? { label: value, value: value } : placeholder}
        isSearchable={true}
        styles={colourStyles}
        name="select"
        options={options?.map((option) => ({
          label: option as string,
          value: option as string,
        }))}
      />
    </div>
  );
}
