/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import type { DataWorldChartTypes } from 'src/types/types';
import { calls } from 'src/utils/calls';
import { displayToast } from 'src/utils/displayToast';
import { axiosInstance } from 'src/utils/instances';
import ErrorIcon from '../assets/ErrorIcon';

export const searchForAllCountries = () => {
  return axiosInstance(calls.searchForAllCountries())
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => {
      console.log(err);
      // throw err;
    });
};

export const searchForAllCountriesStaticProps = async (): Promise<
  DataWorldChartTypes[]
> => {
  try {
    const res = await axiosInstance(calls.searchForAllCountriesStaticProps());
    return res?.data?.data?.filter(
      (country: { enabled: boolean }) => country?.enabled === true
    );
  } catch {
    return [];
  }
};

export const searchByFilters = (
  firstName: string,
  lastName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  country: any,
  page?: string,
  pageSize?: string,
  filters?: any,
  domainName?: string
) => {
  return axiosInstance(
    // @ts-ignore
    calls.searchByFilters(
      firstName,
      lastName,
      country,
      page,
      pageSize,
      filters,
      domainName
    )
  )
    .then((res) => {
      const data = res.data?.data;
      if (
        data?.response?.status === 500 ||
        data?.response?.status === 502 ||
        data?.status === 500 ||
        data?.status === 502
      ) {
        displayToast(
          data?.statusText || 'Something went wrong',
          'error',
          <ErrorIcon />
        );
        return Promise.reject(data);
      }
      const totalFound = res?.data?.totalFound;
      const totalMatches = res?.data?.totalMatches;
      const matchesInResponse = res?.data?.matchesInResponse;
      return {
        data,
        totalFound: totalFound,
        totalMatches: totalMatches,
        matchesInResponse: matchesInResponse,
      };
    })
    .catch((err) => {
      console.log('There is an error on the server:', err);
      throw err;
    });
};
