import moment from 'moment';
import type {
  RecordBirthDeathData,
  RecordNameData,
} from '../components/search/SearchRecord';
import { toTitleCase } from './toTitleCase';

export enum SearchInputType {
  FIRST_NAME = 'first-name',
  LAST_NAME = 'last-name',
  COUNTRY = 'country',
}

export function getRandomString(length = 5) {
  const random = `${Math.random()}`;
  return (
    typeof btoa === 'undefined'
      ? Buffer.from(random).toString('base64')
      : btoa(random)
  ).substr(0, length);
}

export function getInitials(words: string) {
  return words
    .trim()
    .split(/\s+/)
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
}

export function capitalizeString(string?: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
}

type GetPercentageStringOptions = {
  lessThanOneSpecialCase?: boolean;
  fixedDecimals?: number;
};

export function getPercentageString(
  value: number,
  {
    lessThanOneSpecialCase = false,
    fixedDecimals,
  }: GetPercentageStringOptions = {}
) {
  if (value < 1 && lessThanOneSpecialCase) {
    return '~1%';
  }
  return `${
    fixedDecimals !== undefined ? value.toFixed(fixedDecimals) : value
  }%`;
}

export function parseRecordBirthData(birthData: RecordBirthDeathData) {
  const month = birthData?.month === 0 ? null : birthData?.month;
  const day = birthData?.day === 0 ? null : birthData?.day;
  const year = birthData?.year === 0 ? null : birthData?.year;
  const isInferred = birthData?.is_inferred;
  const birthCity =
    birthData?.city?.length === 0 || !birthData?.city
      ? null
      : `${birthData?.city}`;
  const birthCounty =
    birthData?.county?.length === 0 || !birthData?.county
      ? null
      : `${birthData?.county}`;
  const birthRegion =
    birthData?.region?.length === 0 || !birthData?.region
      ? null
      : `${birthData?.region}`;
  const birthCountry =
    birthData?.country?.length === 0 || !birthData?.country
      ? null
      : `${toTitleCase(birthData?.country)}`;
  const birthIso3 =
    birthData?.iso_3?.length === 0 || !birthData?.iso_3
      ? null
      : `${toTitleCase(birthData?.iso_3)}`;

  return {
    month,
    day,
    year,
    birthCity,
    birthCounty,
    birthRegion,
    birthCountry,
    birthIso3,
    isInferred,
  };
}

export function parseRecordDeathData(deathData: RecordBirthDeathData) {
  const deathMonth = deathData?.month === 0 ? null : deathData?.month;
  const deathDay = deathData?.day === 0 ? null : deathData?.day;
  const deathYear =
    deathData?.year === 0 || !deathData?.year ? null : deathData?.year;
  const deathCity =
    deathData?.city?.length === 0 || !deathData?.city
      ? null
      : `${deathData?.city}`;
  const deathRegion =
    deathData?.region?.length === 0 || !deathData?.region
      ? null
      : `${deathData?.region}`;
  const deathCountry =
    deathData?.country?.length === 0 || !deathData?.country
      ? null
      : `${toTitleCase(deathData?.country)}`;

  return {
    deathMonth,
    deathDay,
    deathYear,
    deathCity,
    deathRegion,
    deathCountry,
  };
}

export function generateName(personName: RecordNameData) {
  return personName?.maiden_name?.length > 0 &&
    personName?.maiden_name !== personName?.last_name1
    ? personName?.first_name1?.charAt(0).toUpperCase() +
        personName?.first_name1?.slice(1).toLowerCase() +
        ' ' +
        personName?.first_name2?.charAt(0).toUpperCase() +
        personName?.first_name2?.slice(1).toLowerCase() +
        ' ' +
        personName?.last_name1?.charAt(0).toUpperCase() +
        personName?.last_name1?.slice(1).toLowerCase() +
        ', nee ' +
        personName?.maiden_name?.charAt(0)?.toUpperCase() +
        personName?.maiden_name?.slice(1)?.toLowerCase()
    : `${personName?.first_name1} ${personName?.first_name2} ${personName?.last_name1}`
        ?.split(' ')
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
        )
        ?.join(' ');
}

export function capitalizeWords(string: string) {
  // capitalize words inside string, e.g. "manea ionut-marius" => "Manea Ionut-Marius"
  return string
    ?.split(' ')
    ?.map((word) =>
      word
        ?.split('-')
        ?.map(
          (wordPart) =>
            wordPart?.charAt(0)?.toUpperCase() +
            wordPart?.slice(1)?.toLowerCase()
        )
        ?.join('-')
    )
    ?.join(' ');
}

export function generateDateString(
  day: number | null | undefined,
  month: number | null | undefined,
  year: number | null | undefined
) {
  if (!day) {
    if (!month) {
      if (!year) {
        return '';
      } else {
        return `${year}`;
      }
    } else {
      return moment(`${month}/${year}`, 'MM/YYYY').format('MMMM, YYYY');
    }
  } else {
    return moment(`${day}/${month}/${year}`, 'DD/MM/YYYY').format(
      'MMMM DD, YYYY'
    );
  }
}
