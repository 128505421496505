import Link from 'next/link';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/store';
import ArrowLeftIcon from '../../assets/ArrowLeftIcon';

interface BackButtonProps {
  url: string;
  label?: string;
  prefetch?: boolean;
  forceGoBack?: boolean;
  title?: string;
}

export default function BackButton({
  url,
  label = 'Back',
  title,
  prefetch,
  forceGoBack = false,
}: BackButtonProps) {
  const router = useRouter();
  const { isNavigationFromTheApp } = useSelector(
    (state: RootState) => state.user
  );

  const [isButtonHovered, setIsButtonHovered] = React.useState(false);

  const handleButtonHovered = (hoverState: boolean) => () => {
    setIsButtonHovered(hoverState);
  };

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (
      isNavigationFromTheApp ||
      forceGoBack ||
      (window !== undefined && window.history?.length > 2)
    ) {
      event.preventDefault();
      router.back();
    } else {
      router.push('/');
    }
  };

  return (
    <Link
      href={url}
      title={title}
      prefetch={!!prefetch}
      onClick={handleClick}
      className="tablet:mt-[15px] group mt-[8px] w-fit py-[10px]"
      onMouseEnter={handleButtonHovered(true)}
      onMouseLeave={handleButtonHovered(false)}
    >
      <div className="flex cursor-pointer items-center">
        <ArrowLeftIcon
          fill={isButtonHovered ? '#34A28A' : '#257C6B'}
          className="text-[#257C6B] group-hover:text-[#34A28A]"
        />
        <p className="ml-[10px] cursor-pointer text-[14px] font-medium leading-[28px] tracking-[-0.14px] text-[#257C6B] group-hover:text-[#34A28A]">
          {label}
        </p>
      </div>
    </Link>
  );
}
