import * as React from 'react';

function QuestionMarkIconCircle(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M6.33331 11.0002H7.66665V9.66683H6.33331V11.0002ZM6.99998 0.333496C6.1245 0.333496 5.25759 0.505935 4.44876 0.840966C3.63992 1.176 2.90499 1.66706 2.28593 2.28612C1.03569 3.53636 0.333313 5.23205 0.333313 7.00016C0.333313 8.76827 1.03569 10.464 2.28593 11.7142C2.90499 12.3333 3.63992 12.8243 4.44876 13.1594C5.25759 13.4944 6.1245 13.6668 6.99998 13.6668C8.76809 13.6668 10.4638 12.9645 11.714 11.7142C12.9643 10.464 13.6666 8.76827 13.6666 7.00016C13.6666 6.12468 13.4942 5.25778 13.1592 4.44894C12.8241 3.6401 12.3331 2.90517 11.714 2.28612C11.095 1.66706 10.36 1.176 9.5512 0.840966C8.74237 0.505935 7.87546 0.333496 6.99998 0.333496ZM6.99998 12.3335C4.05998 12.3335 1.66665 9.94016 1.66665 7.00016C1.66665 4.06016 4.05998 1.66683 6.99998 1.66683C9.93998 1.66683 12.3333 4.06016 12.3333 7.00016C12.3333 9.94016 9.93998 12.3335 6.99998 12.3335ZM6.99998 3.00016C6.29274 3.00016 5.61446 3.28111 5.11436 3.78121C4.61426 4.28131 4.33331 4.95959 4.33331 5.66683H5.66665C5.66665 5.31321 5.80712 4.97407 6.05717 4.72402C6.30722 4.47397 6.64636 4.3335 6.99998 4.3335C7.3536 4.3335 7.69274 4.47397 7.94279 4.72402C8.19284 4.97407 8.33331 5.31321 8.33331 5.66683C8.33331 7.00016 6.33331 6.8335 6.33331 9.00016H7.66665C7.66665 7.50016 9.66665 7.3335 9.66665 5.66683C9.66665 4.95959 9.3857 4.28131 8.8856 3.78121C8.3855 3.28111 7.70722 3.00016 6.99998 3.00016Z"
        fill="#9FA3A9"
      />
    </svg>
  );
}

export default QuestionMarkIconCircle;
